/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Contact us"), "\n", React.createElement(_components.p, null, "Here is a list of different ways you can contact us. There is no faster way than the others, pick your favorite and leave us a note, we'll try our best to help you:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/The_Forwarder"
  }, "Twitter – @The_Forwarder")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/the.great.forwarder/"
  }, "Facebook – the.great.forwarder")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "mailto:say.hi@forwarder.cc"
  }, "Email – say.hi@forwarder.cc")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
