import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import "./style.css";

type PropTypes = {
  type?: "default" | "primary" | "primary-alt" | "error";
  link?: boolean;
  disabled?: boolean;
  to?: string;
  href?: string;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
};

export function Button({
  type = "default",
  link = false,
  disabled = false,
  to,
  href,
  onClick,
  className,
  children,
}: PropTypes) {
  const buttonClassName = classNames("button", className, `button--${type}`, {
    "button--link": link,
  });

  if (disabled) {
    return (
      <a className={classNames(buttonClassName, { disabled })}>{children}</a>
    );
  } else if (!to) {
    return (
      <a href={href} onClick={onClick} className={buttonClassName}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={buttonClassName}>
      {children}
    </Link>
  );
}
