import React from "react";

export function Footer() {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    fetch("/api/v1/stats")
      .then((response) => response.json())
      .then(({ messages }) => setCount(() => messages));
  }, []);

  return (
    <div className="app-footer">
      <div className="app-wrapper app-wrapper--footer">
        <div className="app-footer_group">
          {count.toLocaleString()} messages received
        </div>

        <div className="app-footer_group">
          <span
            role="img"
            aria-label="Finger pointing right"
            style={{ marginRight: 8 }}
          >
            👉
          </span>
          <a href="mailto:say.hi@forwarder.cc">say.hi@forwarder.cc</a>
        </div>
      </div>
    </div>
  );
}
