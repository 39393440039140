import React from "react";
import { Link } from "gatsby";

import { Button } from "../Button";
import { Logo } from "../Logo";

import "./style.css";

export const Header = () => (
  <div className="app-header">
    <div className="app-wrapper app-wrapper--header">
      <div className="app-header_group">
        <Link to="/" style={{ display: "inline-flex" }}>
          <Logo withTitle />
        </Link>
      </div>

      <div className="app-header_group">
        <Button href="/app/auth/sign-in" link>
          Sign in
        </Button>

        <Button href="/app/auth/sign-up" type="primary-alt">
          Create your form
        </Button>
      </div>
    </div>
  </div>
);
