import React from "react";
import classNames from "classnames";
import { HeadFC } from "gatsby";

import { Header } from "../App/Header";
import { Footer } from "../App/Footer";

import "./style.css";

const SEO_TITLE = "Forwarder.cc";
const SEO_DESCRIPTION =
  "Transform your static form into a fully working one in less than a minute";

type PropTypes = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export function Page({ className, ...props }: PropTypes) {
  return (
    <div className="app">
      <Header />
      <div className={classNames("page", className)} {...props} />
      <Footer />
    </div>
  );
}

export const Head: HeadFC<object, { title?: string }> = ({ pageContext }) => (
  <>
    <title>{pageContext.title ?? SEO_TITLE}</title>
    <meta name="description" content={SEO_DESCRIPTION} />
    <link rel="canonical" href="https://forwarder.cc" />

    <meta property="og:url" content="https://forwarder.cc/" />
    <meta property="og:title" content={SEO_TITLE} />
    <meta property="og:description" content={SEO_DESCRIPTION} />
  </>
);
