import React from "react";
import Link from "gatsby-link";

type PropTypes = {
  pages: any;
  children: React.ReactNode;
};

export function Wrapper({ pages, children }: PropTypes) {
  const pagesByCategory = React.useMemo(
    () =>
      pages.reduce((memo, page) => {
        const category = page.node.frontmatter.category;
        memo[category] = memo[category] || [];
        memo[category].push(page);
        return memo;
      }, {}),
    [pages]
  );

  return (
    <div className="app-wrapper">
      <nav className="sidebar">
        <ul>
          {Object.entries(pagesByCategory).map(([category, pages]) => (
            <li key={category}>
              <h2>{category}</h2>
              <ul>
                {pages.map(
                  ({
                    node: {
                      id,
                      frontmatter: { path, title },
                    },
                  }) => (
                    <li key={id}>
                      <Link to={path} activeClassName="active">
                        {title}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </li>
          ))}
        </ul>
      </nav>

      {children}
    </div>
  );
}
