import React, { Children } from "react";

import "./style.css";

export function Breadcrumb({ children }: { children: React.ReactNode }) {
  return (
    <nav className="breadcrumb">
      <ul>{Children.map(children, (item) => item && <li>{item}</li>)}</ul>
    </nav>
  );
}
